<template>
  <div class="fitures_wrap">
    <div class="story">
      <div class="title">
        <h1>
          <i></i>
          残疾人作品展销
        </h1>
      </div>
      <div class="lists">
        <div v-for="item in figureList" :key="item.id">
          <router-link :to="'/shoppingMall/detail?id='+item.id" target="_blank">
            <div class="img"><img :src="item.productImage" alt=""></div>
            <h4 class="line_clamp1">
              <router-link to="">{{item.productName}}</router-link>
            </h4>
          </router-link>
        </div>
      </div>
      <status type='record' v-show="figureList.length <= 0"></status>
      <div class="more-button" @click="goRouter('/shoppingMall')">
        更多残疾人作品展销
      </div>
    </div>
  </div>
</template>
<script>
import status from '@/components/public/status.vue'
export default {
  data() {
    return {
      figureList: [],
    };
  },
  components:{status},
  methods: {
    //人物风采
     product() {
     this.$api.productList({
        pageNum:1,
        pageSize:10
      }).then(res =>{
        if (res.data.success) {
          this.figureList = res.data.data.records;
        }
      })
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id
        },
      });
      window.open(href, "_blank")
    },
  },
  created() {
    this.product();
  },
};
</script>
<style lang="less">
.fitures_wrap {
  width: 1200px;
  margin: 0 auto;

  .more-button {
    width: 100%;
    height: 54px;
    background: rgba(217, 217, 217, 0.1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    cursor: pointer;
    &:hover {
      color: #00924c;
    }
    margin: 44px 0 36px;
  }

  .story {
    margin-top: 42px;
    padding-bottom: 60px;

    .title {
      margin-bottom: 22px;

      h1 {
        position: relative;
        font-size: 28px;
        font-weight: bold;
        padding-left: 16px;
        cursor: pointer;
        font-family: PingFangSC-Semibold, PingFang SC;

        i {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 4px;
          height: 30px;
          background: linear-gradient(180deg,
          rgba(0, 146, 76, 0.8) 0%,
          #ffffff 100%);
        }

        &:hover {
          color: #00924b;
        }
      }
    }

    .lists{
      &>div{
        display: inline-block;
        vertical-align: top;
        width: 230px;
        height: 172px;;
        position: relative;
        margin-top: 12px;
        &:not(:nth-of-type(5n)){
          margin-right: 12px;
        }
        .img{
          width: 100%;
          height: 100%;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            background-color: #F6F6F6;
            transition: all .8s;
          }
          &:hover{
            img{
              transform: scale(1.1);
            }
          }
        }
        h4{
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          background: rgba(0,18,10,0.5000);
          line-height: 28px;
          font-weight: 500;
          text-align: center;
          a{
            color: #FFF;
            font-size: 14px;
            &:hover{
              color: #FFF !important;
            }

          }
        }
      }
    }
  }
}
</style>
