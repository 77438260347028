<template>
  <div class="second_wrap">
    <div class="second">
      <div class="left">
        <div class="title">
          <h1>
            <i aria-hidden="true"></i>
            <router-link to="/news/">新闻动态</router-link>
          </h1>
        </div>
        <div class="world new_content flex">
          <div class="carousel">
            <el-carousel height="198px" @change="carouselNew">
              <el-carousel-item v-for="(item, index) in newList" :key="index">
                <img :src="item.thumbnail" :alt="item.title" class="err_img" @click="goRouter('/news/newDetail',item.id)" />
              </el-carousel-item>
            </el-carousel>
            <div class="new_content" v-for="(item, index) in newList" :key="index" v-show="newShow == index" @click="goRouter('/news/newDetail',item.id)">
              <h2>
                <a class="line_clamp2" href="javascript:void (0);">{{ item.title }}</a>
              </h2>
              <p class="text line_clamp2 cursorP">　{{ item.conAbstract }}</p>
            </div>
          </div>
          <ul class="list">
            <li v-for="(item, index) in newList" :key="index" @click="goRouter('/news/newDetail',item.id)">
              <h3 class="line_clamp1">
                <span v-if="item.hasTop == 1" class="hot">置顶</span><a href="javascript:void (0);">{{ item.title }}</a>
              </h3>
              <!--              <div>-->
              <!--                <span>{{ item.createTime | handleTime }}</span>-->
              <!--              </div>-->
            </li>
          </ul>
        </div>
        <div class="button" @click="goRouter('/news/')">
          更多新闻动态
        </div>
      </div>
      <div class="right">
        <div class="title flex" style="justify-content: space-between;">
          <h1><i></i>
            <router-link to="/train/" target="_blank">职业培训</router-link>
          </h1>
          <span style="color:#666666;cursor: pointer;" @click="goRouter('/train/')">更多职业培训</span>
        </div>
        <ul class="train">
          <template v-for="(item,index) in trainList">
            <trainItem style="width:100%;height: 185px;" :data="item" />
          </template>
          <!-- <li class="box cursorP flex" v-for="(item,index) in trainList.slice(0,2)" :key="index"
              @click="goRouter('/training/trainDetail/',item.id)">
            <div class="banner relative">
              <el-image :src="item.picurl">
                <div slot="error" class="image-slot">
                  <img src="~/static/public/defaults.png" alt="">
                </div>
              </el-image>
              <p class="name">
                机构项目培训
              </p>
            </div>
            <div class="info relative">
              <h1><a href="javascript:void (0);">{{ item.trainName }}</a><br/>

              </h1>
              <div class="source" v-if="item.trainAddress"><span>{{ item.trainAddress }}</span></div>
              <p class="color99 line_clamp1">报名时间：{{
                  item.trainBeginTime
                }}—{{ item.trainEndTime }}
              </p>
              <p class="color99 line_clamp1">培训地点：{{ item.trainAddress }}
              </p>
              <p class="color99 line_clamp1"><img src="~/static/lanzhou/index/blackPhone.png" class="inline-block middle"
                                                  v-if="item.linkPhone">{{item.linkPhone}}</p>
              <div class="status" :class="item.ingType=='2'?'backgroundFc':'background99'">
                {{item.ingType=='2'?'报名中':'已结束'}}
              </div>
            </div>
          </li> -->
        </ul>
        <!-- <div class="button" @click="goRouter('/train/')">
          更多职业培训
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import trainItem from '@/components/public/trainItem';
export default {
  components: {
    trainItem
  },
  data() {
    return {
      newList: '',
      trainList: [],
      newShow: 0
    };
  },
  methods: {
    // 新闻动态
    news() {
      this.$api
        .newsListApi({
          pageNum: 1,
          pageSize: 7,
          city: '兰州市',
          leve: '本省',
          consultationClassName: '兰州新闻动态'
        })
        .then(res => {
          if (res.data.success) {
            this.newList = res.data.data.records;
          }
        })
        .catch(err => {});
    },
    //职业培训
    trainApi() {
      this.$api
        .queryCourseList({
          adressCityId: '',
          adressProvinceId: '',
          adressThreeCityId: '',
          disableLevel: '',
          disableType: '',
          heatOrder: '',
          assessOrder: '',
          registrationStatus: '',
          trainIng: '',
          trainType: '',
          pageNum: 1,
          pageSize: 2
        })
        .then(res => {
          res = res.data;
          if (res.success) {
            this.trainList = res.data?.courseListOutDTOIPage?.records ?? [];
          }
        });
      // this.$api
      //     .getHomeTrainApi({pageSize: 4, location: "p24"})
      //     .then((res) => {
      //       if (res.data.success) {
      //         this.trainList = res.data.data;
      //       }
      //     });
    },
    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id
        }
      });
      window.open(href, '_blank');
    },
    carouselNew(i) {
      this.newShow = i;
    }
  },
  created() {
    this.news();
    this.trainApi();
  }
};
</script>
<style lang="less">
.second_wrap {
  background: #fff;

  .second {
    width: 1200px;
    margin: 42px auto;
    display: flex;

    .title {
      margin-bottom: 24px;

      h1 {
        position: relative;
        font-size: 28px;
        font-weight: bold;
        padding-left: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;

        a {
          color: #333333;
        }

        i {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 4px;
          height: 30px;
          background: linear-gradient(180deg, rgba(0, 146, 76, 0.8) 0%, #ffffff 100%);
        }

        &:hover {
          color: #00924b;
        }
      }
    }

    .left {
      flex: 1;
      margin-right: 10px;

      .new_content {
        position: relative;
        .carousel {
          width: 348px;
          margin-right: 18px;

          .el-carousel {
            img {
              width: 100%;
              height: 100%;
            }

            .el-carousel__indicators {
              transform: inherit;
              left: inherit;
              right: 8px;
            }

            .el-carousel__indicator {
              padding: 0;
              margin-right: 4px;

              .el-carousel__button {
                width: 6px;
                height: 6px;
                background: #000000;
                opacity: 0.5;
                border-radius: 50%;
              }

              &.is-active {
                .el-carousel__button {
                  opacity: 1;
                  background: #00924b;
                }
              }
            }
          }

          h2 {
            padding-top: 6px;
            a {
              font-size: 16px;
              font-weight: bold;
              line-height: 22px;
            }
          }
          .text {
            position: absolute;
            top: 50px;
            left: 0;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
          }
        }
      }

      .list {
        width: 318px;

        li {
          position: relative;
          //border-bottom: 1px solid #f9f9f9;
          margin-bottom: 23px;

          &:last-child {
            border: none;
          }

          h3 {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 400;
            line-height: 22px;
            font-size: #333;
            &:hover {
              color: #00924c;
            }

            .hot {
              font-size: 11px;
              color: white;
              padding: 1px 5px;
              border-radius: 2px;
              background: #fc6c38;
              margin-right: 6px;
              vertical-align: middle;
            }
          }

          //div {
          //  padding: 4px 0;
          //  span {
          //    font-family: PingFangSC-Regular, PingFang SC;
          //    font-weight: 400;
          //    color: #999999;
          //    line-height: 20px;
          //  }
          //}
        }
      }
    }

    .right {
      flex: 1;

      ul {
        li {
          height: 138px;
          border-radius: 2px;
          border: 1px solid rgba(217, 217, 217, 0.3);
          padding: 12px;
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }

          .banner {
            margin-right: 12px;

            img {
              width: 156px;
              height: 114px;
              background: #d9d9d9;
              border-radius: 2px;
            }

            .name {
              position: absolute;
              top: 6px;
              right: 6px;
              font-size: 12px;
              color: white;
              padding: 0 10px;
              background: rgba(25, 25, 25, 0.6);
            }
          }

          .info {
            flex: 1;

            h1 {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: bold;
              line-height: 22px;
            }

            .source {
              display: inline-flex;
              align-items: center;
              height: 24px;
              background: #f9f9f9;
              border-radius: 2px;
              border: 1px solid rgba(217, 217, 217, 0.1);
              font-size: 12px;
              padding: 0 5px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 17px;
              margin-top: 6px;
              margin-bottom: 12px;
            }

            p {
              margin-bottom: 6px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #666666;
              line-height: 20px;
            }
          }
        }
      }

      .button {
        margin-top: 27px;
      }
    }

    .button {
      width: 100%;
      height: 54px;
      background: rgba(217, 217, 217, 0.2);
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      cursor: pointer;
      //margin-top: 10px;
      &:hover {
        color: #00924c;
      }
    }
  }
}
</style>
