<template>
  <div class="fitures_wrap">
    <div class="story">
      <div class="title">
        <h1>
          <i></i>
          人物故事
        </h1>
      </div>
      <ul class="list flex">
        <li
            v-for="(item, index) in figureList.slice(0,5)"
            :key="index"
            @click="goRouter('/news/newDetail', item.id)"
        >
          <div class="banner">
            <img :src="item.thumbnail" alt="" width="100%" height="100%" class="err_image">
          </div>
          <h1 class="line_clamp2">
            <a href="javascript:void(0);">{{ item.title }}</a>
          </h1>
          <p class="line_clamp2" v-html="item.conAbstract"></p>
        </li>
      </ul>
      <div class="more-button" @click="goRouter('/news/people_list/')">
        更多人物故事
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      figureList: "",
    };
  },
  methods: {
    //人物风采
    async figures() {
      let res = await this.$api.getHomeCjrfcsApi({
        pageSize:4
      });
      if (res.data.success) {
        this.figureList = res.data.data;
      }
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id
        },
      });
      window.open(href, "_blank")
    },
  },
  created() {
    this.figures();
  },
};
</script>
<style lang="less">
.fitures_wrap {
  width: 1200px;
  margin: 0 auto;

  .more-button {
    width: 100%;
    height: 54px;
    background: rgba(217, 217, 217, 0.1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    cursor: pointer;
    &:hover {
      color: #00924c;
    }
    margin: 44px 0 36px;
  }

  .story {
    margin-top: 42px;
    padding-bottom: 60px;

    .title {
      margin-bottom: 22px;

      h1 {
        position: relative;
        font-size: 28px;
        font-weight: bold;
        padding-left: 16px;
        cursor: pointer;
        font-family: PingFangSC-Semibold, PingFang SC;

        i {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 4px;
          height: 30px;
          background: linear-gradient(180deg,
          rgba(0, 146, 76, 0.8) 0%,
          #ffffff 100%);
        }

        &:hover {
          color: #00924b;
        }
      }
    }

    .list li {
      position: relative;
      width: 220px;
      margin-right: 24px;

      &:hover .banner img {
        transform: scale(1.1);
      }

      &:last-child {
        margin-right: 0;
      }

      .banner {
        width: 100%;
        height: 204px;
        overflow: hidden;

        .el-image {
          height: 100%;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            transition: 0.8s;
          }

          .err_image {
            //width: 100%;
            height: 100%;
          }
        }

      }

      h1 {
        font-size: 16px;
        font-weight: bold;
        margin: 10px 0;
      }

      p {
        position: absolute;
        left: 0;
        bottom: -30px;
        color: #666666;
        line-height: 20px;
        margin-top: 6px;
      }
    }
  }
}
</style>
